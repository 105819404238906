import type { CSSProperties, ReactElement } from 'react';

import loadable from '@loadable/component';
import classNames from 'classnames';

import { BpkContentBubble } from '@skyscanner/backpack-web/bpk-component-flare';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import { PRODUCT_GROUP } from '../../constants';

import type { SearchControlsProps } from 'common-types/types/CarHireSearchControlsProps';
import type { SearchControlsWrapperProps } from 'common-types/types/SearchControlsWrapper';
import type { Vertical } from 'common-types/types/Vertical';
import type { HotelsSearchControlsWrapperProps } from 'common-types/types/hotels-components/HotelSearchControlsWrapperProps';

import STYLES from './SearchControlsWrapper.module.scss';

const searchControlsMap = {
  [PRODUCT_GROUP.HOTELS]: loadable(
    () => import('../HotelSearchControlsWrapper'),
  ),
  [PRODUCT_GROUP.CAR_HIRE]: loadable(() => import('../CarHireSearchControls')),
};

const SearchControls = (
  vertical: Vertical,
  carHireProps?: SearchControlsProps,
  hotelsProps?: HotelsSearchControlsWrapperProps,
  flightsHtmlContent?: string,
  background?: string,
) => {
  const searchWrapperClasses = [STYLES.SearchWrapper];

  if (background) {
    searchWrapperClasses.push(STYLES.SearchWrapperLarge);
  }

  switch (vertical) {
    case PRODUCT_GROUP.FLIGHTS: {
      if (!flightsHtmlContent) {
        throw Error('Vertical is flights but flightsHtmlContent is empty');
      }

      return (
        <div
          data-testid="flights-search-controls"
          className={classNames(searchWrapperClasses)}
        >
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: flightsHtmlContent }} />
        </div>
      );
    }
    case PRODUCT_GROUP.HOTELS: {
      if (!hotelsProps) {
        throw Error('Vertical is Hotels but hotelsProps is empty');
      }
      const VerticalSearchControls = searchControlsMap[vertical];

      return (
        <div
          data-testid="hotels-search-controls"
          className={STYLES.HotelsSearchWrapper}
        >
          <VerticalSearchControls {...hotelsProps} />
        </div>
      );
    }
    case PRODUCT_GROUP.CAR_HIRE: {
      if (!carHireProps) {
        throw Error('Vertical is CarHire but carHireProps is empty');
      }
      const VerticalSearchControls = searchControlsMap[vertical];
      return (
        <div
          data-testid="car-hire-search-controls"
          className={classNames(searchWrapperClasses)}
        >
          <VerticalSearchControls {...carHireProps} />
        </div>
      );
    }
    default: {
      throw Error(`SearchControlsWrapper: Unrecognized vertical: ${vertical}`);
    }
  }
};

const SearchControlsWrapper = ({
  background,
  carHireProps,
  flightsHtmlContent,
  h1,
  hotelsProps,
  showFlare = false,
  sponsored = false,
  vertical,
}: SearchControlsWrapperProps) => {
  const innerContentClasses = [STYLES.InnerContent];

  if (background) {
    innerContentClasses.push(STYLES.InnerContentLarge);
  }

  let InnerElement: ReactElement;

  if (sponsored) {
    InnerElement = (
      <div className={classNames(STYLES.Content, STYLES.SponsoredContent)}>
        <div
          style={{ ['--bg-image' as keyof CSSProperties]: background }}
          className={STYLES.SmallSponsoredImage}
        />
        <BpkText
          className={STYLES.Title}
          tagName="h1"
          textStyle={TEXT_STYLES.heading2}
        >
          {h1}
        </BpkText>
        {SearchControls(
          vertical,
          carHireProps,
          hotelsProps,
          flightsHtmlContent,
          background,
        )}
      </div>
    );
  } else {
    InnerElement = (
      <div className={STYLES.Content}>
        <BpkText
          className={STYLES.Title}
          tagName="h1"
          textStyle={background ? TEXT_STYLES.hero4 : TEXT_STYLES.heading1}
        >
          {h1}
        </BpkText>
        {SearchControls(
          vertical,
          carHireProps,
          hotelsProps,
          flightsHtmlContent,
          background,
        )}
      </div>
    );
  }

  return (
    <BpkContentBubble
      style={
        background
          ? {
              backgroundImage: background,
            }
          : {}
      }
      className={STYLES.Hero}
      contentClassName={innerContentClasses.join(' ')}
      showPointer={showFlare}
      rounded={false}
      content={InnerElement}
      flareProps={{ className: STYLES.Flare }}
    />
  );
};

export default SearchControlsWrapper;
